const clientChildren = [
  {
    path: 'clientHome',
    name: 'ClientHome',
    component: () => import('@/views/clientSide/clientHome/index'),
    meta: { title: '首页' }
  },
  {
    path: 'longSailHouse',
    name: 'LongSailHouse',
    component: () => import('@/views/clientSide/longSailHouse/index'),
    meta: { title: '长帆之家' }
  },
  {
    path: 'colorfulLongSail',
    name: 'ColorfulLongSail',
    component: () => import('@/views/clientSide/colorfulLongSail/index'),
    meta: { title: '七彩长帆' }
  },
  {
    path: 'knowledge',
    name: 'ClientKnowledge',
    component: () => import('@/views/clientSide/clientKnowledge/index'),
    meta: { title: '知识库', theme: 'white', searchHide: true, pageBg: '#fff' }
  },
  {
    path: 'knowledgeSearch',
    name: 'ClientKnowledgeSearch',
    component: () => import('@/views/clientSide/searchResults/index'),
    meta: { title: '知识库搜索', theme: 'white', searchHide: true, pageBg: '#fff' }
  },
  {
    path: 'moreArticles',
    name: 'ClientMoreArticles',
    component: () => import('@/views/clientSide/moreArticles/index'),
    meta: { title: '公司动态', keepAlive: false }
  },
  {
    path: 'articleDetail/:id',
    name: 'ClientArticleDetail',
    component: () => import('@/views/clientSide/articleDetail/index'),
    meta: { title: '文章详情', pageBg: '#fff', keepAlive: false, showBar: true, breadcrumb: true }
  },
  {
    path: 'searchResult',
    name: 'ClientSearchResult',
    component: () => import('@/views/clientSide/searchResults/index'),
    meta: { title: '搜索', pageBg: '#fff', searchHide: true }
  },
  {
    path: 'topicDetail',
    name: 'ClientTopicDetail',
    component: () => import('@/views/clientSide/topicDetail/index'),
    meta: { title: '话题详情', moduleName: '七彩长帆' }
  },
  {
    path: 'topicPostDetail/:id',
    name: 'ClientTopicPostDetail',
    component: () => import('@/views/clientSide/topicPostDetail/index'),
    meta: { title: '贴子详情', moduleName: '七彩长帆', pageBg: '#fff', keepAlive: false, showBar: true, breadcrumb: true }
  },
  {
    path: 'companyInstitution',
    name: 'ClientCompanyInstitution',
    component: () => import('@/views/clientSide/companyInstitution/index'),
    meta: { title: '公司制度' }
  },
  {
    path: 'longSailSchool',
    name: 'LongSailSchool',
    component: () => import('@/views/clientSide/longSailSchool/index'),
    meta: { title: '长帆学堂' }
  },
  {
    path: 'longSailSchool/moreArticles',
    name: 'LongSailSchoolMoreArticles',
    component: () => import('@/views/clientSide/longSailSchool/moreArticles/index'),
    meta: { title: '更多文章/课程', keepAlive: false }
  },
  {
    path: 'longSailSchool/classifyMoreCourse',
    name: 'LongSailSchoolClassifyMoreCourse',
    component: () => import('@/views/clientSide/longSailSchool/classifyMoreCourse/index'),
    meta: { title: '更多课程' }
  },
  {
    path: 'course/courseDetail/:id',
    name: 'ClientCourseDetail',
    component: () => import('@/views/clientSide/course/courseDetail/index'),
    meta: { title: '课程详情', breadcrumb: true, pageBg: '#fff', keepAlive: false }
  },
  {
    path: 'course/seriesCourseDetail/:id',
    name: 'ClientSeriesCourseDetail',
    component: () => import('@/views/clientSide/course/courseDetail/seriesCourseDetail'),
    meta: { title: '系列课程详情', keepAlive: false }
  },
  {
    path: 'longSailSchool/moreTrainingPlan',
    name: 'ClientMoreTrainingPlan',
    component: () => import('@/views/clientSide/longSailSchool/moreTrainingPlan/index'),
    meta: { title: '更多培训计划' }
  },
  {
    path: 'longSailSchool/moreLecturer',
    name: 'ClientMoreLecturer',
    component: () => import('@/views/clientSide/longSailSchool/moreLecturer/index'),
    meta: { title: '更多讲师', keepAlive: false }
  },
  {
    path: 'longSailSchool/trainPlanDetail',
    name: 'ClientTrainPlanDetail',
    component: () => import('@/views/clientSide/longSailSchool/trainPlanDetail/index'),
    meta: { title: '培训计划详情', breadcrumb: true }
  },
  {
    path: 'longSailSchool/lectureDetail/:id',
    name: 'ClientLectureDetail',
    component: () => import('@/views/clientSide/longSailSchool/lectureDetail/index'),
    meta: { title: '讲师详情', keepAlive: false }
  },
  {
    path: 'longSailSchool/rankDetail',
    name: 'ClientRankDetail',
    component: () => import('@/views/clientSide/longSailSchool/rankDetail/index'),
    meta: { title: '榜单详情', keepAlive: false }
  },
  {
    path: 'clientExamCenter/:id/:isPractice?',
    name: 'ClientExamCenter',
    component: () => import('@/views/clientSide/examCenter/index'),
    meta: { title: '考试中心' }
  },
  {
    path: 'clientExamination/:taskId/:isPractice?',
    name: 'ClientExamination',
    component: () => import('@/views/clientSide/examCenter/examination/index'),
    meta: { title: '考试中', keepAlive: false }
  },
  {
    path: 'examinationDetail/:taskId/:examId/:client?',
    name: 'UserExamDetail',
    component: () => import('@/views/clientSide/examCenter/examination/index'),
    meta: { title: '学员试卷', keepAlive: false }
  },
  {
    path: 'clientExamResult/:id/:isPractice?',
    name: 'ClientExamResult',
    component: () => import('@/views/clientSide/examCenter/examResult/index'),
    meta: { title: '考试结果', keepAlive: false }
  },
  {
    path: 'clientMoreExamClassify',
    name: 'MoreExamClassify',
    component: () => import('@/views/clientSide/longSailSchool/moreExamClassify/index'),
    meta: { title: '试题专区', keepAlive: false }
  }
]
export default clientChildren
